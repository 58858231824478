
/**
 * 下载文件流
 * res 为文件流信息
 * type 将文件流以什么类型下载 有 pdf 和 excel、msword
 * fileName 文件名
 */
export const downloadStream = (res,type,fileName) => {
  let typeStr = '';
  switch(type){// 根据类型下载文件
    case 'xls': typeStr = 'application/vnd.ms-excel';break;
    case 'xlsx': typeStr = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';break;
    // 默认excel文件
    default: typeStr = 'application/vnd.ms-excel';break;
  }
  
  // 创建blob对象，解析流数据
  const blob = new Blob([res], {
  	// 设置返回的文件类型
  	// type: 'application/vnd.ms-excel'
    type: typeStr
  })
  const elink = document.createElement('a')
  elink.download = fileName
  elink.style.display = 'none'
  elink.href = URL.createObjectURL(blob)
  document.body.appendChild(elink)
  elink.click()
  URL.revokeObjectURL(elink.href) // 释放URL 对象
  document.body.removeChild(elink)
}
